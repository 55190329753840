import React from 'react';
import Layout from '../../components/Layout';
import thanks from '../../img/design/thank-you.png';
import envelope from '../../img/design/envelope.png';

const ThankYou = () => (
    <Layout>
        <section className="section section--salmon section--with-mask mask-four">
            <div className="container">
                <section className="section">
                    <div className="sitteroo-blog-post has-text-centered p-5">
                        <img src={thanks} alt="" />
                        <span className="is-sr-only">Thank you</span>
                        <p className="has-text-centered sitteroo-blog-list-heading--pastel mt-2">
                            for subscription
                        </p>
                        <img src={envelope} alt="" />
                        <p className="has-text-centered sitteroo-blog-list-heading--pastel mt-2">
                            Please check your email for details.
                        </p>
                    </div>
                </section>
            </div>
        </section>
    </Layout>
);

export default ThankYou;
